// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-post-post-tsx": () => import("./../../../src/components/Post/Post.tsx" /* webpackChunkName: "component---src-components-post-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articulos-tsx": () => import("./../../../src/pages/articulos.tsx" /* webpackChunkName: "component---src-pages-articulos-tsx" */),
  "component---src-pages-auto-estudio-tsx": () => import("./../../../src/pages/auto-estudio.tsx" /* webpackChunkName: "component---src-pages-auto-estudio-tsx" */),
  "component---src-pages-conocenos-tsx": () => import("./../../../src/pages/conocenos.tsx" /* webpackChunkName: "component---src-pages-conocenos-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-paso-a-paso-tsx": () => import("./../../../src/pages/paso-a-paso.tsx" /* webpackChunkName: "component---src-pages-paso-a-paso-tsx" */),
  "component---src-pages-testimonios-tsx": () => import("./../../../src/pages/testimonios.tsx" /* webpackChunkName: "component---src-pages-testimonios-tsx" */)
}

